import { useState, ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import { Footer } from './Footer'
import { Header } from './Header'
import { MenuOverlay } from './MenuOverlay'
import { HeaderMeta } from './HeaderMeta'

type Props = {
  children: ReactNode
}

const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const LayoutContent = styled.div`
  flex: 1 0 auto;
`

const LayoutFooter = styled.div`
  flex-shrink: 0;
`

export function Base({ children }: Props) {
  const [overlayOpen, setOverlayOpen] = useState(false)
  const router = useRouter()

  useEffect(() => {
    function handleRouteChange() {
      setOverlayOpen(false)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  function toggleMenuOverlay() {
    setOverlayOpen((state) => !state)
  }

  return (
    <Layout>
      <Header />
      <HeaderMeta isMenuOverlayOpen={overlayOpen} toggleMenuOverlay={toggleMenuOverlay} />
      <LayoutContent>{children}</LayoutContent>
      <LayoutFooter>
        <Footer />
      </LayoutFooter>
      <MenuOverlay isActive={overlayOpen} />
    </Layout>
  )
}
