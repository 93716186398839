import styled, { css } from 'styled-components'

import { container, flex, flexItem, Margin, margin, media } from '../base'
import { resizeImage, StoryblokBlock } from '../services'
import { Person, FadeIn } from '../components'
import { Career, useRandomTeam } from '../features/team'
import { useState } from 'react'
import { useIntl } from 'react-intl'

type TeamBlock = StoryblokBlock & {
  team: any // TODO
  visual_margin?: Margin
}

type Props = {
  block: TeamBlock
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
`

const Content = styled.div`
  @media (min-width: 640px) {
  }

  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div<any>`
  @media (min-width: 640px) {
  }

  @media ${media.minTablet} {
    ${flexItem(8, 2)}
    ${(props) =>
      props.$large &&
      css`
        font-size: 24px; // TODO: Use from font('body-large')
      `}
  }
`

const Items = styled.ul`
  padding: 0;
  margin: 0 0 -40px 0;
  list-style: none;

  @media (min-width: 640px) {
    margin: -22px;
    display: flex;
    flex-wrap: wrap;
  }
`

const Item = styled.li`
  margin-bottom: 40px;

  @media (min-width: 640px) {
    flex: 0 0 auto;
    flex-basis: 50%;
    max-width: 50%;
    padding: 22px;
    display: flex;
  }

  @media ${media.minNotebook} {
    flex-basis: 33.333333%;
    max-width: 33.333333%;
  }
`

const filters = css`
  display: flex;
  gap: 16px;
  margin: 0 0 48px 0;
  padding: 0;
  list-style: none;
`

const filterButton = css`
  border: 1px solid currentColor;
  border-radius: 0;
  background: transparent;
  font-family: inherit;
  padding: 4px 12px;
  color: inherit;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  height: 38px;
  transition: background 0.25s, color 0.25s, border 0.25s;

  &:hover {
    background: var(--color-primary);
    color: white;
    border: 1px solid var(--color-primary);
  }
`

const filterButtonActive = css`
  background: var(--color-primary);
  color: white;
  border: 1px solid var(--color-primary);
`

const filterButtonReset = css`
  height: 38px;
  width: 38px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`

export function TeamBlock({ block }: Props) {
  const intl = useIntl()

  const [currentLocation, setCurrentLocation] = useState<'Liechtenstein' | 'Schweiz' | null>(null)
  let members: any = useRandomTeam(block.team)

  // Filter members by location
  members = members.filter((member: any) => {
    if (!currentLocation) {
      return true
    }

    if (!member.content.locations) {
      return false
    }

    return member.content.locations.includes(currentLocation)
  })

  // Add career placeholder
  members = [...members.slice(0, 1), 'PLACEHOLDER', ...members.slice(1)]

  const onUpdateLocation = (location: 'Liechtenstein' | 'Schweiz') => {
    setCurrentLocation(location)
  }

  const onResetLocation = () => {
    setCurrentLocation(null)
  }

  return (
    <Container $margin={block.visual_margin}>
      <Content>
        <Main>
          <ul css={filters}>
            <li>
              <button
                onClick={() => onUpdateLocation('Liechtenstein')}
                css={[filterButton, currentLocation === 'Liechtenstein' && filterButtonActive]}
              >
                Liechtenstein
              </button>
            </li>
            <li>
              <button
                onClick={() => onUpdateLocation('Schweiz')}
                css={[filterButton, currentLocation === 'Schweiz' && filterButtonActive]}
              >
                {intl.locale === 'de' ? 'Schweiz' : 'Switzerland'}
              </button>
            </li>
            {currentLocation && (
              <li>
                <button onClick={onResetLocation} css={[filterButton, filterButtonReset]}>
                  ✕
                </button>
              </li>
            )}
          </ul>
          <Items>
            {members.map((item: any) => {
              if (item === 'PLACEHOLDER') {
                return (
                  <Item key="placeholder">
                    <FadeIn>
                      <Career />
                    </FadeIn>
                  </Item>
                )
              } else {
                const resizedPhoto = resizeImage(item.content.photo, 640, 880)
                return (
                  <Item key={item.id}>
                    <FadeIn>
                      <Person showCV={true} showMail={false} person={{ ...item, photo: resizedPhoto }} />
                    </FadeIn>
                  </Item>
                )
              }
            })}
          </Items>
        </Main>
      </Content>
    </Container>
  )
}
