import { css } from 'styled-components'

export type Options = {
  size: 'medium' | 'large'
  multiline?: boolean
  error?: boolean
}

export function input({ multiline, size, error }: Options) {
  return css`
    border: none;
    background: var(--color-background-light);
    color: var(--color-primary);
    display: block;
    width: 100%;
    padding: 8px 16px;
    font-family: inherit;
    font-size: inherit;
    outline-color: var(--color-primary);
    border-radius: 0 !important;

    ${size === 'large' &&
    css`
      padding: 16px 22px;
    `}

    &:focus {
    }

    &::placeholder {
      color: #8194a8;
    }

    ${multiline &&
    css`
      height: 120px;
    `}

    ${error &&
    css`
      background: #ec121229;
    `}
  `
}
