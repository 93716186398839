import { css } from 'styled-components'

export const container = css`
  position: relative;
  overflow: hidden;
`

export const containerError = css`
  color: #ff5252;
`

export const checkbox = css`
  position: absolute;
  left: 4px;
  top: 6px;
  opacity: 0;

  &:checked ~ label {
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 8px;
      left: 3px;
      background: currentColor;
    }
  }

  &:invalid ~ label {
    &::after {
      color: green;
    }
  }
`

export const label = css`
  display: block;
  padding-left: 26px;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid currentColor;
    position: absolute;
    top: 5px;
    left: 0;
  }
`
