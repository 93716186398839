import React from 'react'

import * as styles from './TextInput.css'

type Props = {
  placeholder?: string
  type?: string // TODO: Inherit from HTML input element.
  multiline?: boolean
  size?: 'medium' | 'large'
  error?: boolean
  [key: string]: any
}

export const TextInput = React.forwardRef<any, Props>(
  ({ size = 'medium', type = 'text', placeholder, multiline, error, ...props }, ref) => {
    if (multiline) {
      return <textarea placeholder={placeholder} css={styles.input({ multiline, size, error })} ref={ref} {...props} />
    }

    return <input type={type} placeholder={placeholder} css={styles.input({ size, error })} ref={ref} {...props} />
  }
)
