import { useRef } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { useIntl } from 'react-intl'

import { resizeImage } from '../services/storyblok'
import { background, container, flex, flexItem, Margin, margin, media } from '../base'
import { StoryblokBlock, StoryblokLink, StoryblokAsset, StoryblokHTML } from '../services'
import { CTALink, FadeIn } from '../components'

type CTABlock = StoryblokBlock & {
  text: StoryblokHTML
  link: StoryblokLink
  image: StoryblokAsset
  visual_margin?: Margin
}

type Props = {
  block: CTABlock
}

const Wrapper = styled.div<any>`
  // ${background('lighter')}
  position: relative;
  overflow: hidden;
  ${(props) => props.$margin && margin(props.$margin)}
`

const Container = styled.div`
  ${container()}
  position: relative;
  z-index: 1;
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div`
  padding-top: 100px;
  padding-bottom: 200px;
  min-height: 720px;

  @media ${media.minTablet} {
    ${flexItem(8, 2)}
  }
`

const Text = styled.div`
  // TODO: load from font('foo')
  font-size: 32px;
  margin-bottom: 20px;

  @media ${media.minTablet} {
    white-space: pre;
    font-size: 36px;
    line-height: 48px;
  }
`

const Background = styled.div`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  max-width: 100%;
`

const Action = styled.div``

export function CTABlock({ block }: Props) {
  const intl = useIntl()

  // TODO: resizeImage()

  // const [elementTop, setElementTop] = useState(0)
  const ref = useRef<HTMLInputElement>(null)
  // const { scrollY } = useViewportScroll()

  // const y = useTransform(scrollY, [elementTop, elementTop + 1], [0, 0.25], {
  //   clamp: false,
  // })

  // useEffect(() => {
  //   if (ref && ref.current) {
  //     setElementTop(ref.current.offsetTop)
  //   }
  // }, [ref])

  // TODO: block.link not resolved...

  const resizedImage = resizeImage(block.image, 1920, 720)

  return (
    <Wrapper ref={ref} $margin={block.visual_margin}>
      <Container>
        <Content>
          <Main>
            <FadeIn>
              <Text dangerouslySetInnerHTML={{ __html: block.text }} />
            </FadeIn>
            <Action>
              <CTALink href={block.link?.cached_url}>{intl.formatMessage({ id: 'CTABlock.contact' })}</CTALink>
            </Action>
          </Main>
        </Content>
      </Container>
      <Background>
        <Image src={resizedImage.url} alt="" layout="fill" objectFit="cover" quality={100} />
      </Background>
    </Wrapper>
  )
}
