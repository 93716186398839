import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import * as styles from './PublicationOrderForm.css'
import { TextInput, Dropdown, Dot, Button, Checkbox } from '../../components'

type FormData = {
  contact: {
    title: 'male' | 'female' | null
    firstname: string
    lastname: string
    email: string
  }
  invoiceAddress: {
    company?: string
    street: string
    pobox?: string
    zip: string
    city: string
    country: string
  }
  hasShippingAddress?: boolean
  shippingAddress?: {
    title: 'male' | 'female' | null
    firstname: string
    lastname: string
    company?: string
    street: string
    pobox?: string
    zip: string
    city: string
    country: string
  }
  message?: string
}

type Status = undefined | 'SENDING' | 'SENT'

type Props = {
  name: string
  slug: string
  price: string
}

export function PublicationOrderForm({ name, slug, price }: Props) {
  const intl = useIntl()
  const [status, setStatus] = useState<Status>(undefined)
  const {
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  function onSubmit(data: FormData) {
    const payload = {
      ...data,
      name,
      slug,
      price,
    }

    setStatus('SENDING')

    fetch('/api/order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then(() => {
        setStatus('SENT')
        reset()
      })
  }

  return (
    <div css={styles.wrapper}>
      <h1 css={styles.title}>
        {intl.formatMessage({ id: 'publication.orderForm' })}
        <Dot /> / CHF {Number(price).toFixed(2)}
      </h1>
      {status === 'SENT' ? (
        <h2>{intl.formatMessage({ id: 'publication.orderForm.confirmation' })}</h2>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div css={styles.section}>
            <h2 css={styles.title}> {intl.formatMessage({ id: 'publication.orderForm.contact' })}</h2>
            <div css={styles.row}>
              <Dropdown
                placeholder={`${intl.formatMessage({ id: 'publication.orderForm.title' })}*`}
                error={!!errors.contact?.title}
                {...register('contact.title', { required: true, value: null })}
                options={[
                  { key: 'female', value: intl.formatMessage({ id: 'publication.orderForm.title.female' }) },
                  { key: 'male', value: intl.formatMessage({ id: 'publication.orderForm.title.male' }) },
                ]}
              />
            </div>
            <div css={[styles.row, styles.rowItems]}>
              <div css={styles.rowItem}>
                <TextInput
                  placeholder={`${intl.formatMessage({ id: 'publication.orderForm.firstname' })}*`}
                  error={!!errors.contact?.firstname}
                  {...register('contact.firstname', { required: true })}
                />
              </div>
              <div css={styles.rowItem}>
                <TextInput
                  placeholder={`${intl.formatMessage({ id: 'publication.orderForm.lastname' })}*`}
                  error={!!errors.contact?.lastname}
                  {...register('contact.lastname', { required: true })}
                />
              </div>
            </div>
            <div css={styles.row}>
              <TextInput
                placeholder={`${intl.formatMessage({ id: 'publication.orderForm.email' })}*`}
                error={!!errors.contact?.email}
                {...register('contact.email', { required: true })}
              />
            </div>
          </div>

          <div css={styles.section}>
            <h2 css={styles.title}>{intl.formatMessage({ id: 'publication.orderForm.invoiceAddress' })}</h2>
            <div css={styles.row}>
              <TextInput
                placeholder={intl.formatMessage({ id: 'publication.orderForm.company' })}
                error={!!errors.invoiceAddress?.company}
                {...register('invoiceAddress.company')}
              />
            </div>
            <div css={[styles.row, styles.rowItems]}>
              <div css={styles.rowItem}>
                <TextInput
                  placeholder={`${intl.formatMessage({ id: 'publication.orderForm.street' })}*`}
                  error={!!errors.invoiceAddress?.street}
                  {...register('invoiceAddress.street', { required: true })}
                />
              </div>
              <div css={styles.rowItem}>
                <TextInput
                  placeholder={intl.formatMessage({ id: 'publication.orderForm.pobox' })}
                  {...register('invoiceAddress.pobox')}
                />
              </div>
            </div>
            <div css={[styles.row, styles.rowItems]}>
              <div css={styles.rowItem}>
                <TextInput
                  placeholder={`${intl.formatMessage({ id: 'publication.orderForm.zip' })}*`}
                  error={!!errors.invoiceAddress?.zip}
                  {...register('invoiceAddress.zip', { required: true })}
                />
              </div>
              <div css={styles.rowItem}>
                <TextInput
                  placeholder={`${intl.formatMessage({ id: 'publication.orderForm.city' })}*`}
                  error={!!errors.invoiceAddress?.city}
                  {...register('invoiceAddress.city', { required: true })}
                />
              </div>
            </div>
            <div css={styles.row}>
              <TextInput
                placeholder={`${intl.formatMessage({ id: 'publication.orderForm.country' })}*`}
                error={!!errors.invoiceAddress?.country}
                {...register('invoiceAddress.country', { required: true })}
              />
            </div>
          </div>

          <div css={styles.section}>
            <h2 css={styles.title}>{intl.formatMessage({ id: 'publication.orderForm.shippingAddress' })}</h2>
            <div css={styles.row}>
              <Checkbox error={!!errors.hasShippingAddress} {...register('hasShippingAddress')}>
                {intl.formatMessage({ id: 'publication.orderForm.hasShippingAddress' })}
              </Checkbox>
            </div>
            {watch('hasShippingAddress') && (
              <div>
                <div css={styles.row}>
                  <Dropdown
                    placeholder={`${intl.formatMessage({ id: 'publication.orderForm.title' })}*`}
                    error={!!errors.shippingAddress?.title}
                    {...register('shippingAddress.title', {
                      required: watch('hasShippingAddress') ? true : false,
                      value: null,
                    })}
                    options={[
                      { key: 'female', value: intl.formatMessage({ id: 'publication.orderForm.title.female' }) },
                      { key: 'male', value: intl.formatMessage({ id: 'publication.orderForm.title.male' }) },
                    ]}
                  />
                </div>
                <div css={[styles.row, styles.rowItems]}>
                  <div css={styles.rowItem}>
                    <TextInput
                      placeholder={`${intl.formatMessage({ id: 'publication.orderForm.firstname' })}*`}
                      error={!!errors.shippingAddress?.firstname}
                      {...register('shippingAddress.firstname', {
                        required: watch('hasShippingAddress') ? true : false,
                      })}
                    />
                  </div>
                  <div css={styles.rowItem}>
                    <TextInput
                      placeholder={`${intl.formatMessage({ id: 'publication.orderForm.lastname' })}*`}
                      error={!!errors.shippingAddress?.lastname}
                      {...register('shippingAddress.lastname', {
                        required: watch('hasShippingAddress') ? true : false,
                      })}
                    />
                  </div>
                </div>
                <div css={styles.row}>
                  <TextInput
                    placeholder={intl.formatMessage({ id: 'publication.orderForm.company' })}
                    error={!!errors.shippingAddress?.company}
                    {...register('shippingAddress.company')}
                  />
                </div>
                <div css={[styles.row, styles.rowItems]}>
                  <div css={styles.rowItem}>
                    <TextInput
                      placeholder={`${intl.formatMessage({ id: 'publication.orderForm.street' })}*`}
                      error={!!errors.shippingAddress?.street}
                      {...register('shippingAddress.street', { required: true })}
                    />
                  </div>
                  <div css={styles.rowItem}>
                    <TextInput
                      placeholder={intl.formatMessage({ id: 'publication.orderForm.pobox' })}
                      {...register('shippingAddress.pobox')}
                    />
                  </div>
                </div>
                <div css={[styles.row, styles.rowItems]}>
                  <div css={styles.rowItem}>
                    <TextInput
                      placeholder={`${intl.formatMessage({ id: 'publication.orderForm.zip' })}*`}
                      error={!!errors.shippingAddress?.zip}
                      {...register('shippingAddress.zip', { required: watch('hasShippingAddress') ? true : false })}
                    />
                  </div>
                  <div css={styles.rowItem}>
                    <TextInput
                      placeholder={`${intl.formatMessage({ id: 'publication.orderForm.city' })}*`}
                      error={!!errors.shippingAddress?.city}
                      {...register('shippingAddress.city', { required: watch('hasShippingAddress') ? true : false })}
                    />
                  </div>
                </div>
                <div css={styles.row}>
                  <TextInput
                    placeholder={`${intl.formatMessage({ id: 'publication.orderForm.country' })}*`}
                    error={!!errors.shippingAddress?.country}
                    {...register('shippingAddress.country', { required: watch('hasShippingAddress') ? true : false })}
                  />
                </div>
              </div>
            )}
          </div>

          <div css={styles.section}>
            <TextInput
              multiline
              placeholder={`${intl.formatMessage({ id: 'publication.orderForm.message' })}`}
              error={!!errors.message}
              {...register('message', { required: false })}
            />
          </div>

          <div css={styles.rowLast}>
            <Button type="submit" disabled={status === 'SENDING'}>
              {status === 'SENDING'
                ? intl.formatMessage({ id: 'publication.orderForm.button.loading' })
                : intl.formatMessage({ id: 'publication.orderForm.button' })}
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
