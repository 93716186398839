import { GetStaticPropsContext } from 'next'
import Head from 'next/head'

import { useStoryblok, storyblokClient, getTitle, getDescription } from '../services/storyblok'
import { Base } from '../layouts'
import { Blocks } from '../blocks'
import { TeamStory } from '../features/team'
import { PublicationStory } from '../features/publications'

export default function Page({ story, locale }: any) {
  story = useStoryblok(story, true, locale)

  const title = getTitle(story)
  const description = getDescription(story)

  return (
    <>
      <Head>
        <title>{title} | WeTrust Group</title>
        {description && <meta name="description" content={description} />}
      </Head>
      <Base>
        {story.content.component === 'Team' && <TeamStory story={story} />}
        {story.content.component === 'Page' && <Blocks content={story.content} />}
        {story.content.component === 'Publication' && <PublicationStory story={story} />}
      </Base>
    </>
  )
}

export async function getStaticProps({ params = {}, preview = false, locale = 'de' }: GetStaticPropsContext) {
  let slug = 'home'

  if (Array.isArray(params?.path)) {
    slug = params.path.join('/')
  }

  try {
    var { data } = await storyblokClient.get(`cdn/stories/${slug}`, {
      version: 'draft',
      resolve_relations:
        'PublicationsPreviewBlock.publications,ReferenceBlock.block,TeamBlock.team,ContactsBlock.people,CTABlock.link',
      resolve_links: 'story',
      language: locale,
    })
  } catch (err) {
    return {
      notFound: true,
    }
  }

  // CTA for content type `Team` (details page)
  if (data?.story?.content?.component === 'Team') {
    const { data: innerData } = await storyblokClient.get(`cdn/stories/2618f59b-30ed-4181-ad9f-d11aec64baa0/cta`, {
      version: 'draft',
      language: locale,
    })
    data.story.cta = innerData.story
  }

  // Publications
  if (data?.story?.content?.blocks?.length) {
    for (const block of data.story.content.blocks) {
      if (block.component === 'PublicationsBlock') {
        const { data } = await storyblokClient.get(`cdn/stories`, {
          version: 'published',
          language: locale,
          starts_with: 'publikationen/',
        })
        const publications = data.stories.filter((publication: any) => publication.content.component === 'Publication')
        block.publications = publications
      }
    }
  }

  return {
    props: {
      story: data ? data.story : false,
      preview: preview || false,
      locale,
    },
    revalidate: 3600,
  }
}

export async function getStaticPaths() {
  return {
    paths: [
      { params: { path: false }, locale: 'de' },
      // { params: { path: ['impressum'] }, locale: 'de' },
      // { params: { path: ['datenschutz'] }, locale: 'de' },
    ],
    fallback: 'blocking',
  }
}
