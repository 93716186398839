import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Head from 'next/head'

import { resizeImage } from '../../services/storyblok'
import { container, media, flex, flexItem } from '../../base'
import { Blocks } from '../../blocks'
import { Intro } from './Intro'
import { Placeholder } from '../../components/Publication/Placeholder'
import { PublicationOrderForm } from './PublicationOrderForm'
import { EmailIcon, FacebookIcon, TwitterIcon, LinkedinIcon } from '~/icons'
import { translatePublication } from './translatePublication'

const Wrapper = styled.div``

const Container = styled.div`
  ${container()}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Media = styled.div`
  width: 320px;
  max-width: 100%;
  margin-bottom: 48px;
  margin-top: 36px;

  @media ${media.minTablet} {
    ${flexItem(3, 0)}
    margin-top: calc(68px + var(--gap));
    padding-left: 12px;
  }
`

const Main = styled.div`
  @media ${media.minTablet} {
    ${flexItem(6, 1)}
    order: 1;
    padding-top: 68px;
  }
`

const Photo = styled.img`
  display: block;
  height: auto;
  width: 100%;
  max-width: 380px;
`

const BlocksWrapper = styled.div`
  margin-left: calc(-1 * var(--container-padding-x));
  margin-right: calc(-1 * var(--container-padding-x));
`

const ShareList = styled.ul`
  margin: 24px -10px 24px -10px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
`

const ShareItem = styled.li``

const ShareLink = styled.a`
  display: block;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--color-secondary);
  }

  svg {
    display: block;
    height: 28px;
  }
`

export function PublicationStory({ story }: any) {
  const [url, setUrl] = useState<string>('')
  const router = useRouter()
  const resizedImage = story.content.preview?.filename ? resizeImage(story.content.preview, 492, 700) : null

  useEffect(() => {
    const currentURL = encodeURI(window.location.href)
    setUrl(currentURL)
  }, [])

  story = translatePublication(story, String(router.locale))

  return (
    <>
      <Head>
        {resizedImage && <meta property="og:image" content={resizedImage.url} />}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${story.name} | WeTrust`} />
        <meta property="og:description" content={`${story.content.meta} | WeTrust`} />
      </Head>
      <Wrapper>
        <Intro name={story.name} description={story.content.meta} />
        <Container>
          <Content>
            <Media>
              {resizedImage ? <Photo src={resizedImage.url} alt="" /> : <Placeholder />}
              <ShareList>
                <ShareItem>
                  <ShareLink href={`mailto:?subject=${story.name}&body=${url}`} target="_blank" rel="noopener">
                    <EmailIcon />
                  </ShareLink>
                </ShareItem>
                <ShareItem>
                  <ShareLink
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <LinkedinIcon />
                  </ShareLink>
                </ShareItem>
                <ShareItem>
                  <ShareLink href={`https://twitter.com/intent/tweet?text=${url}`} target="_blank" rel="noopener">
                    <TwitterIcon />
                  </ShareLink>
                </ShareItem>
                <ShareItem>
                  <ShareLink
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <FacebookIcon />
                  </ShareLink>
                </ShareItem>
              </ShareList>
            </Media>
            <Main>
              <BlocksWrapper>
                <Blocks content={story.content} withLayout={false} />
              </BlocksWrapper>
              {router.query?.order && (
                <PublicationOrderForm name={story.name} slug={story.slug} price={story.content.order_price} />
              )}
            </Main>
          </Content>
        </Container>
      </Wrapper>
    </>
  )
}
