import styled, { css } from 'styled-components'

import { renderReactText, StoryblokBlock, getTitle } from '../services'
import { container, flex, flexItem, Margin, margin, media } from '../base'
import { CTALink, FadeIn } from '../components'

type ContentBlock = StoryblokBlock & {
  text: any // TODO
  cta_link?: any // TODO
  cta_label?: any // TODO
  visual_margin?: Margin
  visual_large?: boolean
  visual_width_small?: boolean
}

type Props = {
  block: ContentBlock
  withLayout?: boolean
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div<any>`
  @media ${media.minTablet} {
    ${flexItem(8, 2)}

    ${(props) =>
      props.$smallWidth &&
      css`
        ${flexItem(6, 3)}
      `}
  }
`

const Text = styled.div<any>`
  h1,
  h2 {
    margin: 0;

    &:not(:first-of-type) {
      margin-top: 30px;
    }
  }

  h1 {
    margin: 0 0 1.5rem 0;
  }

  @media ${media.minTablet} {
    ${(props) =>
      props.$large &&
      css`
        font-size: 24px; // TODO: Use from font('body-large')
      `}
  }
`

const Action = styled.div`
  margin-top: 30px;
`

function ContentBlockLayout({ children, visual_width_small, withLayout }: any) {
  if (withLayout) {
    return (
      <Content>
        <Main $smallWidth={visual_width_small}>{children}</Main>
      </Content>
    )
  }

  return <>{children}</>
}

export function ContentBlock({ block, withLayout = true }: Props) {
  const hasLink =
    (block.cta_link.linktype === 'story' && block.cta_link.id) ||
    (block.cta_link.linktype === 'url' && block.cta_link.cached_url) ||
    (block.cta_link.linktype === 'asset' && block.cta_link.url)

  let linkTitle = ''
  if (block.cta_link.linktype === 'story' && block.cta_link.id) {
    linkTitle = getTitle(block.cta_link.story)
  }

  return (
    <Container $margin={block.visual_margin}>
      <ContentBlockLayout visual_width_small={block.visual_width_small} withLayout={withLayout}>
        <FadeIn>
          <Text $large={block.visual_large}>{renderReactText(block.text)}</Text>
        </FadeIn>
        {hasLink && (
          <Action>
            <CTALink href={block.cta_link.cached_url} target={block.cta_link.linktype === 'asset' ? '_blank' : null}>
              {block.cta_label || linkTitle || 'Download'}
            </CTALink>
          </Action>
        )}
      </ContentBlockLayout>
    </Container>
  )
}
