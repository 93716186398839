import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'

import { container, flex, flexItem, Margin, margin, media } from '../base'
import { resizeImage, StoryblokBlock } from '../services'
import { Person } from '../components'

type ContactsBlock = StoryblokBlock & {
  people: any // TODO
  visual_margin?: Margin
}

type Props = {
  block: ContactsBlock
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
  border-bottom: 1px solid #fff; // collapsing margins
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div<any>`
  @media ${media.minTablet} {
    ${flexItem(8, 2)}
    ${(props) =>
      props.$large &&
      css`
        font-size: 24px; // TODO: Use from font('body-large')
      `}
  }
`

const Items = styled.ul`
  list-style: none;
  margin: 0 0 -30px 0;
  padding: 0;

  @media ${media.minTablet} {
    margin: -10px;
    display: flex;
    flex-wrap: wrap;
  }
`

const Item = styled.li`
  margin-bottom: 30px;

  @media ${media.minTablet} {
    flex: 0 0 auto;
    flex-basis: 33.333333%;
    max-width: 33.333333%;
    padding: 10px;
    display: flex;
  }
`

export function ContactsBlock({ block }: Props) {
  const intl = useIntl()
  const isSingular = block.people.length <= 1

  return (
    <Container $margin={block.visual_margin}>
      <Content>
        <Main>
          <h2>
            {isSingular
              ? intl.formatMessage({ id: 'contacts.singular' })
              : intl.formatMessage({ id: 'contacts.plural' })}
          </h2>
          <Items>
            {block.people.map((team: any) => {
              const resizedPhoto = resizeImage(team.content.photo, 640, 780)

              return (
                <Item key={team.id}>
                  <Person showCV={false} showMail={true} person={{ ...team, photo: resizedPhoto }} />
                </Item>
              )
            })}
          </Items>
        </Main>
      </Content>
    </Container>
  )
}
