import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { font } from '../../base'
import { Link } from '../Link'
import { CTALink } from '../CTALink'

const Team = styled.div`
  a {
    text-decoration: none;
  }
`

const Photo = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  max-width: 320px;
`

const Name = styled.h2`
  ${font('body')}
  margin: 0 0 1px 0;
`

const Description = styled.div``

const CV = styled.div`
  display: block;
  margin-top: 4px;
`

// TODO: Don't rely on Storyblok structure (person.content.description) but create custom type instead.

export function Person({ person }: any) {
  const intl = useIntl()
  const withPage = person.content.page === 'true'

  return (
    <Team>
      {withPage ? (
        <Link href={`/team/${person.slug}`}>
          <Photo src={person.photo.url} alt="" />
        </Link>
      ) : (
        <Photo src={person.photo.url} alt="" />
      )}
      <Name>{person.name}</Name>
      <Description>{person.content.description}</Description>
      {person.content.email && (
        <CV>
          <CTALink href={`mailto:${person.content.email}`} target="_blank" rel="noopener">
            {intl.formatMessage({ id: 'person.mail' })}
          </CTALink>
        </CV>
      )}
    </Team>
  )
}
