import styled, { css } from 'styled-components'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'

const sharedWrapperStyles = css`
  text-decoration: none;
  position: relative;
  padding-left: 18px;
  display: inline-block;
  padding-right: 6px;
`

const Link = styled(motion.a)`
  ${sharedWrapperStyles}
`

const Text = styled(motion.span)`
  position: relative;
  z-index: 2;
  display: block;
`

const Dot = styled(motion.span)`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  /* background: var(--color-secondary); */
  position: absolute;
  top: -11px;
  left: -18px;
  display: block;
`

const dot2Variants = {
  base: {
    scale: 0.25,
    background: '#48A851',
  },
  hover: {
    scale: 1,
    background: '#D9ECDD',
    // background: '#48A851',
    transition: {
      // type: `spring`,
      // stiffness: 100,
      duration: 0.35,
    },
  },
}

const text2Variants = {
  base: {
    left: 0,
  },
  hover: {
    left: -5,
    transition: {
      duration: 0.35,
    },
  },
}

// TODO: Handle external links (e.g. downloads etc) as well.

export function CTALink({ href, children, locale, ...props }: any) {
  const router = useRouter()
  const targetLocale = locale || router.locale || 'de'

  if (!href) {
    return null
  }

  if (href.startsWith('http')) {
    return (
      <Link href={href} initial="base" whileHover="hover" {...props}>
        <Dot variants={dot2Variants} />
        <Text variants={text2Variants}>{children}</Text>
      </Link>
    )
  }

  return (
    <>
      <NextLink href={href} passHref locale={targetLocale}>
        <Link initial="base" whileHover="hover" {...props}>
          <Dot variants={dot2Variants} />
          <Text variants={text2Variants}>{children}</Text>
        </Link>
      </NextLink>
    </>
  )
}
