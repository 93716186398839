import { StoryblokStory } from './types'

export function getTitle(story: StoryblokStory): string {
  if (story.content?.seo_title) {
    return story.content?.seo_title
  }

  // Use translated_slugs when available.
  if (story.translated_slugs) {
    const translation: any = story.translated_slugs.find((translation: any) => {
      return translation.lang === story.lang
    })

    if (translation && translation.name) {
      return translation.name
    }
  }

  return story.name
}

export function getDescription(story: StoryblokStory): string {
  return story.content?.seo_description || null
}
