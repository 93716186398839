import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'

import { container, media } from '../../base'
import { Link } from '../../components'

const Wrapper = styled.footer`
  border-bottom: 16px solid var(--color-primary);
  overflow: hidden;
`

const Container = styled.div`
  ${container()}
`

const Content = styled.div`
  padding-top: 45px;
  padding-bottom: 35px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 44px;

  @media ${media.minTablet} {
    padding-top: 94px;
    padding-bottom: 70px;
    grid-template-columns: repeat(12, 1fr);
  }
`

const Icon = styled.div`
  position: relative;
  top: 6px;
  grid-column: 1 / span 4;

  @media ${media.minTablet} {
    grid-column: 1 / span 2;
  }

  @media ${media.minNotebook} {
  }

  svg {
    display: block;
    height: 66px;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;
  grid-column: 1 / span 4;

  @media ${media.minTablet} {
    grid-column: 4 / span 9;
    flex-direction: row;
    gap: 88px;
  }

  @media ${media.minNotebook} {
    grid-column: 3 / span 6;
    flex-direction: row;
    margin-left: -22px;
  }

  a {
    text-decoration: none;
  }
`

const Meta = styled.div`
  grid-column: 1 / span 4;

  @media ${media.minTablet} {
    grid-column: 1 / span 12;
  }

  @media ${media.minNotebook} {
    grid-column: 9 / span 4;
    align-self: end;
  }

  ul {
    padding: 0;
    margin: 0 -24px;
    list-style: none;
    display: flex;

    @media ${media.minNotebook} {
      justify-content: flex-end;
    }
  }

  li {
    padding: 0 24px;
  }
`

const StyledLink = styled(Link)`
  color: rgba(54, 86, 117, 0.4);
  text-decoration: none;

  &:hover {
    color: var(--color-primary);
  }
`

const Location = styled.div``

const address = css`
  font-style: normal;
  margin-bottom: 10px;
`

export function Footer() {
  const intl = useIntl()

  return (
    <Wrapper>
      <Container>
        <Content>
          <Icon>
            <Link href="/" aria-label="Startseite">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201 109">
                <g transform="translate(.2 .4)" fillRule="nonzero" fill="none">
                  <path
                    d="M40.4 108.1L0 0h22.5c3.2 0 6 2 7.1 4.9l23.2 61.5-12.4 41.7zm49.8 0L49.8 0h22.4c3.2 0 6 2 7.1 4.9l23.1 61.6-12.2 41.6z"
                    fill="#365675"
                  />
                  <circle fill="#48A851" cx="183.3" cy="16.8" r="16.7" />
                  <path d="M150.1 0h-40.3v33.4l22.6-22.6v95.9h25.3V7.6c0-4.2-3.4-7.6-7.6-7.6z" fill="#365675" />
                </g>
              </svg>
            </Link>
          </Icon>
          <Main>
            <Location>
              <address css={address}>
                <strong>WeTrust Group</strong>
                <br />
                Poststrasse 2<br />
                9494 Schaan
                <br />
                Liechtenstein
              </address>
              <a href="tel:+4232333308">{intl.formatMessage({ id: 'phone.abbr' })} +423 233 33 08</a>
              <br />
              <a href="mailto:office@wetrust.li">office@wetrust.li</a>
            </Location>
            <Location>
              <address css={address}>
                <strong>WeTrust Services AG</strong>
                <br />
                Seidenhofstrasse 14
                <br />
                6003 Luzern
                <br />
                {intl.locale === 'de' ? 'Schweiz' : 'Switzerland'}
              </address>
              <a href="tel:+41415157150">{intl.formatMessage({ id: 'phone.abbr' })} +41 41 515 71 50</a>
              <br />
              <a href="mailto:office@wetrust-group.ch">office@wetrust-group.ch</a>
            </Location>
          </Main>
          <Meta>
            <ul>
              <li>
                <StyledLink href="/impressum">{intl.formatMessage({ id: 'imprint' })}</StyledLink>
              </li>
              <li>
                <StyledLink href="/datenschutz">{intl.formatMessage({ id: 'privacy-policy' })}</StyledLink>
              </li>
            </ul>
          </Meta>
        </Content>
      </Container>
    </Wrapper>
  )
}
