import { Publication } from './types'

export function filterPublications(filter: string | undefined, publications: Publication[]) {
  return publications.filter((publication) => {
    if (filter) {
      return publication.content.category === filter
    }
    return true
  })
}
