import { css } from 'styled-components'

import { font } from '../../base'

export const wrapper = css`
  h2 {
    ${font('heading-3')}
  }
`

export const title = css`
  margin-top: 0;
`

export const section = css`
  border-bottom: 1px solid #ccc;
  margin-bottom: 24px;
  padding-bottom: 32px;
`

export const row = css`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const rowLast = css`
  ${row}
  display: flex;
  justify-content: flex-end;
`

export const rowItems = css`
  display: flex;
  flex: 1;
  margin: 0 -0.5rem;
  width: auto;
`

export const rowItem = css`
  flex: 1;
  padding: 0 0.5rem;
`
