import SbEditable from 'storyblok-react'

import { HomeBlock } from './HomeBlock'
import { ContentBlock } from './ContentBlock'
import { CTABlock } from './CTABlock'
import { HeadingBlock } from './HeadingBlock'
import { BoxesBlock } from './BoxesBlock'
import { IntroBlock } from './IntroBlock'
import { ContactBlock } from './ContactBlock'
import { PublicationsPreviewBlock } from './PublicationsPreviewBlock'
import { ReferenceBlock } from './ReferenceBlock'
import { AccordionBlock } from './AccordionBlock'
import { TeamBlock } from './TeamBlock'
import { PublicationsBlock } from './PublicationsBlock'
import { ContactsBlock } from './ContactsBlock'
import { FeaturedBlock } from './FeaturedBlock'
import { GalleryBlock } from './GalleryBlock'

type Props = {
  content: any // TODO
  withLayout?: boolean
}

const components: any = {
  HomeBlock,
  ContentBlock,
  CTABlock,
  HeadingBlock,
  BoxesBlock,
  IntroBlock,
  PublicationsPreviewBlock,
  ContactBlock,
  ReferenceBlock,
  AccordionBlock,
  TeamBlock,
  PublicationsBlock,
  ContactsBlock,
  FeaturedBlock,
  GalleryBlock,
}

export function Block({ block, withLayout }: any) {
  if (typeof components[block.component] !== 'undefined') {
    const Component = components[block.component]
    return (
      <SbEditable content={block}>
        <Component block={block} withLayout={withLayout} />
      </SbEditable>
    )
  }

  // Always return something (even `null`) when no corresponding block was found, because "nothing" isn't a valid React element.
  return null
}

export function Blocks({ content, withLayout = true }: Props) {
  // TODO: Why necessary to prevent erros when working in Storyblok?
  if (!content || !content.blocks) {
    return null
  }

  return (
    <SbEditable content={content}>
      {content.blocks.map((block: any) => (
        <Block block={block} key={block._uid} withLayout={withLayout} />
      ))}
    </SbEditable>
  )
}
