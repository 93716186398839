import styled from 'styled-components'

import { media } from '../../base'
import { MenuButton } from './MenuButton'

const Wrapper = styled.div`
  position: absolute;
  right: 30px;
  z-index: 999;
  top: 20px;

  @media ${media.minTablet} {
    position: fixed;
    top: 75px;
    right: 50px;
  }

  @media (min-width: 1620px) {
    right: auto;
    left: calc(50vw + var(--container-width) / 2 - 100px);
  }
`

export function HeaderMeta({ toggleMenuOverlay, isMenuOverlayOpen }: any) {
  return (
    <Wrapper>
      <MenuButton active={isMenuOverlayOpen} onToggle={toggleMenuOverlay} />
    </Wrapper>
  )
}
