import styled, { css } from 'styled-components'

import { container, flex, flexItem, Margin, margin, media } from '../base'
import { StoryblokBlock } from '../services'
import { Box, FadeIn } from '../components'

type BoxesBlock = StoryblokBlock & {
  boxes: any // TODO
  visual_margin?: Margin
}

type Props = {
  block: BoxesBlock
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div<any>`
  @media ${media.minTablet} {
    ${flexItem(8, 2)}
    ${(props) =>
      props.$large &&
      css`
        font-size: 24px; // TODO: Use from font('body-large')
      `}
  }
`

const Items = styled.ul`
  margin: 0 0 -20px 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @media ${media.minTablet} {
    margin: -10px;
  }
`

const Item = styled.li`
  margin-bottom: 20px;
  display: flex;
  width: 100%;

  @media ${media.minTablet} {
    flex: 0 0 auto;
    flex-basis: 50%;
    max-width: 50%;
    padding: 10px;
    margin: 0;
  }
`

export function BoxesBlock({ block }: Props) {
  return (
    <Container $margin={block.visual_margin}>
      <Content>
        <Main>
          <Items>
            {block.boxes.map((box: any) => {
              const href = box.link.cached_url

              return (
                <Item key={box._uid}>
                  <FadeIn>
                    <Box href={href} {...box} />
                  </FadeIn>
                </Item>
              )
            })}
          </Items>
        </Main>
      </Content>
    </Container>
  )
}
