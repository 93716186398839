import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'

import { font, media, flex, flexItem } from '../../base'
import { resizeImage } from '../../services/storyblok'
import { CTALink } from '../CTALink'
import { Link } from '../Link'
import { Placeholder } from './Placeholder'

const Wrapper = styled.div`
  padding-bottom: 60px; /** because image to bottom: -60px */

  @media ${media.minNotebook} {
    ${flex()}
  }
`

const Media = styled.div`
  background: var(--color-background-light);
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #fff; /* because collapsing margins */
  margin-bottom: 68px;

  @media ${media.minNotebook} {
    ${flexItem(5)}
    padding: 40px 40px 0 40px;
    margin-bottom: 0;
  }
`

const Main = styled.div`
  @media ${media.minNotebook} {
    ${flexItem(6)}
    padding: 40px 0 0 20px;
  }
`

const imageStyles = css`
  display: block;
  width: 246px;
  max-width: 100%;
  height: auto;
  margin: 0 auto -40px auto;

  @media ${media.minNotebook} {
    margin: 0 0 -60px auto;
  }
`

const Image = styled.img`
  ${imageStyles}
  border: 0.5px solid #707070;
`

const StyledPlaceholder = styled(Placeholder)`
  ${imageStyles}
`

const Meta = styled.div`
  margin-bottom: 6px;
`

const Category = styled.span``

const Title = styled.h2`
  margin: 0 0 6px 0;
  ${font('body-bold')}
  font-size: 20px;
  max-width: 860px;

  a {
    text-decoration: none;
    display: block;
  }

  @media ${media.minTablet} {
    font-size: 24px;
  }
`

const Actions = styled.div`
  margin-top: 30px;

  > a:not(:last-child) {
    margin-right: 30px;
  }

  @media ${media.minTablet} {
    margin-top: 30px;
  }
`

const Description = styled.div``

export function Publication({ publication }: any) {
  const intl = useIntl()
  const previewImage = publication.image?.filename && resizeImage(publication.image, 492, 700)
  const category = intl.messages[`publication.category.${publication.category}`]

  const primaryActionHref = publication.order
    ? `${intl.locale}/publikationen/${publication.slug}?order=1`
    : publication.page
    ? `${intl.locale}/publikationen/${publication.slug}`
    : publication.href

  return (
    <Wrapper>
      <Media>
        <Link href={primaryActionHref}>
          {previewImage ? <Image src={previewImage.url} alt="" /> : <StyledPlaceholder />}
        </Link>
      </Media>
      <Main>
        <Meta>
          {publication.year}
          &nbsp;| <Category>{category}</Category>
        </Meta>
        <Title>
          <Link href={primaryActionHref}>{publication.name}</Link>
        </Title>
        <Description>{publication.meta}</Description>
        <Actions>
          {publication.order ? (
            <>
              {/* Order publication */}
              <CTALink href={`/publikationen/${publication.slug}?order=1`}>
                {intl.formatMessage({ id: 'publication.order' })}
              </CTALink>
              {publication.file.filename && (
                <CTALink href={publication.file.filename} target="_blank">
                  {intl.formatMessage({ id: 'publication.preview' })}
                </CTALink>
              )}
            </>
          ) : (
            <>
              {/* Download or link */}
              {publication.page ? (
                <CTALink href={`/publikationen/${publication.slug}`}>{intl.formatMessage({ id: 'more' })}</CTALink>
              ) : (
                <CTALink href={publication.href} target="_blank" rel="noopener">
                  {publication.isLink
                    ? intl.formatMessage({ id: 'publication.open' })
                    : intl.formatMessage({ id: 'publication.download' })}
                </CTALink>
              )}
            </>
          )}
        </Actions>
      </Main>
    </Wrapper>
  )
}
