import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { StoryblokBlock } from '../services/storyblok'
import { background, container, font, Margin, margin, media } from '../base'
import { Logomark, Dot } from '../components'

type HomeBlock = StoryblokBlock & {
  title: string
  subline?: string
  quotes: any
  visual_margin?: Margin
}

type Props = {
  block: HomeBlock
}

const Wrapper = styled.div<any>`
  ${background('light')}
  ${(props) => margin(props.$margin)}
  overflow: hidden;
`

const Container = styled.div`
  ${container()}
  /* padding-top: 30%; */
  /* padding-bottom: 140px; */
  position: relative;
  min-height: calc(100vh - 116px);
  display: flex;
  align-items: flex-end;
`

const Quote = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
  width: 860px;
  max-width: 100%;
  margin-top: 160px;

  @media ${media.minTablet} {
    margin-bottom: 155px;
  }
`

const Canvas = styled.div`
  overflow: hidden;
`

const Title = styled(motion.div)`
  ${font('visual')}
  transform: translateY(100%);

  @media ${media.maxTablet} {
    display: none;
  }
`

const PhoneTitle = styled(motion.div)`
  ${font('visual')}

  br {
    display: none;
  }

  @media ${media.minTablet} {
    display: none;
  }
`

const Subline = styled(motion.div)`
  font-size: 24px;
  margin-top: 20px;

  @media ${media.maxTablet} {
    display: none;
  }
`

const PhoneSubline = styled(motion.div)`
  font-size: 24px;
  margin-top: 20px;

  @media ${media.minTablet} {
    display: none;
  }
`

const StyledLogomark = styled(Logomark)`
  display: block;
  position: absolute;
  bottom: 140px;
  right: -54px;
  width: 58.333333%;
  color: rgba(255, 255, 255, 0.4);
`

const ScrollIndicator = styled.div`
  position: absolute;
  right: 24px;
  bottom: 38px;
  color: var(--color-secondary);
  pointer-events: none;

  @media ${media.maxTablet} {
    display: none;
  }

  span {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    left: 2px;
    transform: rotate(-90deg);
    transform-origin: center bottom;
    margin-bottom: 30px;
  }

  svg {
    display: block;
    width: 28px;
  }
`

const variants = {
  line0: {
    y: '100%',
    transition: {
      duration: 0.75,
    },
  },
  line1: {
    y: '66.666666%',
    transition: {
      duration: 0.75,
    },
  },
  line2: {
    y: '33.333333%',
    transition: {
      duration: 0.75,
    },
  },
  line3: {
    y: 0,
    transition: {
      duration: 0.75,
    },
  },
}

const phoneVariants = {
  initial: {
    y: '100%',
  },
  animate: {
    y: 0,
    transition: {
      duration: 0.75,
    },
  },
}

const dotAnimationVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 3.5,
    },
  },
}

const sublineAnimationVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 4,
      duration: 0.25,
    },
  },
}

const phoneSublineAnimationVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1,
      duration: 0.25,
    },
  },
}

// TODO: Parallax on Logomark.

function getRandomQuote(quotes: any) {
  const index = Math.floor(Math.random() * quotes.length)
  return quotes[index]
}

export function HomeBlock({ block }: Props) {
  const [quote, setQuote] = useState(getRandomQuote(block.quotes))
  const [line, setLine] = useState(1)
  const interval = useRef<any>()

  useEffect(() => {
    const q = getRandomQuote(block.quotes)
    setQuote(q)
  }, [])

  useEffect(() => {
    interval.current = setInterval(() => {
      setLine((state) => state + 1)
    }, 1250)
    return () => clearInterval(interval.current)
  }, [])

  useEffect(() => {
    if (line >= 3) {
      clearInterval(interval.current)
    }
  }, [line])

  const quoteParts = quote.text.split('\n')

  return (
    <Wrapper $margin={block.visual_margin}>
      <Container>
        <Quote>
          <Canvas>
            <Title initial="line0" animate={`line${line}`} variants={variants}>
              {quoteParts.map((item: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    {item}
                    {index < quoteParts.length - 1 && <br />}
                  </React.Fragment>
                )
              })}
              <motion.span variants={dotAnimationVariants} initial="hidden" animate="visible">
                <Dot />
              </motion.span>
            </Title>
            <PhoneTitle initial="initial" animate="animate" variants={phoneVariants}>
              {quote.text}
              <Dot />
            </PhoneTitle>
          </Canvas>
          {quote.author && (
            <>
              <Subline variants={sublineAnimationVariants} initial="hidden" animate="visible">
                {quote.author}
              </Subline>
              <PhoneSubline variants={phoneSublineAnimationVariants} initial="hidden" animate="visible">
                {quote.author}
              </PhoneSubline>
            </>
          )}
        </Quote>

        <StyledLogomark />

        <ScrollIndicator>
          <span>Scroll</span>
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 29.412 39.169"
            initial={{ y: 0 }}
            animate={{ y: 10 }}
            exit={{ y: 0 }}
            transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2 }}
          >
            <g fill="none" stroke="currentColor" strokeWidth={3}>
              <path d="M14.706 0v36.928" />
              <path d="M1.051 23.658l13.655 13.409 13.655-13.409" />
            </g>
          </motion.svg>
        </ScrollIndicator>
      </Container>
    </Wrapper>
  )
}
