import React from 'react'

import * as styles from './Dropdown.css'

type Props = {
  placeholder?: string
  options: any[] // type
  size?: 'medium' | 'large'
  error?: boolean
  onChange?: any
  value?: any
}

export const Dropdown = React.forwardRef<any, Props>(
  ({ placeholder, size = 'medium', error, options, ...props }, ref) => {
    return (
      <div css={styles.dropdown({ size, error })}>
        <select {...props} ref={ref}>
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option value={option.key} key={option.key}>
              {option.value}
            </option>
          ))}
        </select>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.972 21.894" height="18">
          <g fill="none" stroke="#365675" strokeWidth={2}>
            <path d="M9.486 0v20.48" />
            <path d="m.707 11.7 8.779 8.779 8.778-8.779" />
          </g>
        </svg>
      </div>
    )
  }
)
