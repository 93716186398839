import { Image } from './types'
import { StoryblokAsset } from './types'

export type Filter = {
  width?: number
  height?: number
  smart?: boolean
}

const ASSET_CDN = 'https://img2.storyblok.com'

function getAssetURLParts(assetURL: string) {
  const pathname = assetURL.replace('https://a.storyblok.com/', '')
  const [prefix, spaceID, size, uniqueID, filename] = pathname.split('/')

  return {
    prefix,
    spaceID,
    size,
    uniqueID,
    filename,
    pathname,
  }
}

function calculateWidth(originalWidth: number, originalHeight: number, newHeight: number) {
  return Math.round((originalWidth / originalHeight) * newHeight)
}

function calculateHeight(originalWidth: number, originalHeight: number, newWidth: number): number {
  return Math.round((originalHeight / originalWidth) * newWidth)
}

function transformImage(imageAsset: StoryblokAsset, filter: Filter): Image {
  const parts = getAssetURLParts(imageAsset.filename)
  const smart = filter.smart ? '/smart' : ''
  const url = `${ASSET_CDN}/${filter.width}x${filter.height}${smart}/${parts.pathname}`

  // Calculate the new image sized and respect proportional resizing.
  const size = parts.size.split('x')
  const originalWidth = parseInt(size[0])
  const originalHeight = parseInt(size[1])
  const width =
    filter.width && filter.width !== 0
      ? filter.width
      : calculateWidth(originalWidth, originalHeight, filter.height || 0)
  const height =
    filter.height && filter.height !== 0
      ? filter.height
      : calculateHeight(originalWidth, originalHeight, filter.width || 0)

  return {
    id: String(imageAsset.id),
    url,
    height,
    width,
    alt: imageAsset.alt,
    name: imageAsset.name,
    title: imageAsset.title,
    copyright: imageAsset.copyright,
    asset: imageAsset.filename,
  }
}

export function resizeImage(imageAsset: StoryblokAsset, width: number, height: number, filter?: Filter): Image {
  return transformImage(imageAsset, { width, height, ...filter })
}

export function resizeImageToMax(imageAsset: StoryblokAsset, width: number, height: number, filter?: Filter): Image {
  const parts = getAssetURLParts(imageAsset.filename)
  const [originalWidth, originalHeight] = parts.size.split('x')

  if (parseInt(originalWidth) > parseInt(originalHeight)) {
    return resizeImage(imageAsset, width, 0, filter)
  } else {
    return resizeImage(imageAsset, 0, height, filter)
  }
}
