import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { resizeImage } from '../../services/storyblok'
import { container, media, flex, flexItem } from '../../base'
import { Blocks } from '../../blocks'
import { CTALink } from '../../components'
import { Intro } from './Intro'

const Wrapper = styled.div``

const Container = styled.div`
  ${container()}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
    flex-direction: row;
    padding-left: calc(var(--container-padding-x) / 2);
  }
`

const Media = styled.div`
  margin-bottom: 40px;
  margin-top: 40px;

  @media ${media.minTablet} {
    ${flexItem(3, 0)}
    margin-bottom: 0;
    margin-top: -148px;
  }
`

const Main = styled.div`
  @media ${media.minTablet} {
    ${flexItem(6, 1)}
    order: 1;
    padding-top: 68px;
  }
`

const Meta = styled.ul`
  margin: -12px -24px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 54px;

  li {
    margin: 12px 24px;
  }
`

const Photo = styled.img`
  display: block;
  height: auto;
  width: 100%;
  max-width: 380px;
`

const BlocksWrapper = styled.div`
  // remove container padding
  margin-left: calc(-1 * var(--container-padding-x));
  margin-right: calc(-1 * var(--container-padding-x));
`

export function TeamStory({ story }: any) {
  const intl = useIntl()
  const resizedPhoto = resizeImage(story.content.photo, 640, 780)

  return (
    <Wrapper>
      <Intro name={story.name} description={story.content.description} />
      <Container>
        <Content>
          <Media>
            <Photo src={resizedPhoto.url} alt="" />
          </Media>
          <Main>
            <Meta>
              <li>
                <CTALink href={`mailto:${story.content.email}`}>{intl.formatMessage({ id: 'person.mail' })}</CTALink>
              </li>
              <li>
                <CTALink href={`https://www.wetrust.li/api/vcard?slug=${story.slug}`}>vCard</CTALink>
              </li>
              <li>
                <CTALink href={story.content.cv?.filename} target="_blank" rel="noopener" download>
                  CV (PDF)
                </CTALink>
              </li>
            </Meta>
            <BlocksWrapper>
              <Blocks content={story.content} withLayout={false} />
            </BlocksWrapper>
          </Main>
        </Content>
      </Container>
      <Blocks content={story.cta.content} />
    </Wrapper>
  )
}
