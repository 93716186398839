import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { StoryblokBlock } from '../services'
import { container, flex, flexItem, Margin, margin, media } from '../base'
import { parseDate } from '../utils'
import { Dot, PublicationPreview, CTALink, FadeIn } from '../components'
import { translatePublication } from '~/features/publications'

type PublicationsPreviewBlock = StoryblokBlock & {
  publications: [] // TODO: Type
  visual_margin?: Margin
}

type Props = {
  block: PublicationsPreviewBlock
}

const Wrapper = styled.div<any>`
  ${(props) => props.$margin && margin(props.$margin)}
  background: var(--color-background-lighter);
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${media.minTablet} {
    padding-top: 68px;
    padding-bottom: 68px;
  }
`

const Container = styled.div<any>`
  ${container()}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div`
  @media ${media.minTablet} {
    ${flexItem(8, 2)}
  }
`

const Title = styled.h1`
  margin: 0;
  ${margin('small')}
`

const Items = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  ${margin('medium')}
`

const Item = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
`

const Action = styled.div`
  margin-top: 80px;
`

export function PublicationsPreviewBlock({ block }: Props) {
  const intl = useIntl()

  const publications = block.publications.map((publication) => translatePublication(publication, intl.locale))

  return (
    <Wrapper $margin={block.visual_margin}>
      <Container>
        <Content>
          <Main>
            <Title>
              {intl.formatMessage({ id: 'publications.title' })}
              <Dot />
            </Title>
            <Items>
              {publications.map((publication: any) => {
                if (!publication.content) {
                  return null
                }

                // TODO: Extract into helper function.
                const href = publication.content?.link?.url
                  ? `${intl.locale === 'en' ? 'en/' : ''}${publication.content?.link?.url}`
                  : publication.content?.file?.filename

                const isLink = publication.content?.link?.linktype === 'url'

                return (
                  <Item key={publication.id}>
                    <FadeIn>
                      <PublicationPreview
                        publication={{
                          name: publication.name,
                          category: publication.content.category,
                          date: parseDate(publication.content.datetime),
                          year: publication.content.year,
                          file: publication.content.file,
                          meta: publication.content.meta,
                          page: publication.content.page === 'true', // this is intentional
                          blocks: publication.content.blocks,
                          slug: publication.slug,
                          href,
                          isLink,
                          order:
                            publication.content.order === 'true'
                              ? {
                                  price: Number(publication.content.order_price),
                                  recipients: publication.content.order_recipients,
                                }
                              : undefined,
                        }}
                      />
                    </FadeIn>
                  </Item>
                )
              })}
            </Items>
            <Action>
              <CTALink href="/publikationen">{intl.formatMessage({ id: 'publications.all' })}</CTALink>
            </Action>
          </Main>
        </Content>
      </Container>
    </Wrapper>
  )
}
