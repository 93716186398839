import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useIntl } from 'react-intl'

import { media } from '../../base'
import { Link } from '../Link'

const StyledLink = styled(Link)`
  display: block;
  background: var(--color-background-light);
  text-decoration: none;
  width: 100%;
  height: 100%;
`

const Canvas = styled(motion.div)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Main = styled.div`
  margin-top: auto;
  flex: 1;
`

const Title = styled.h2`
  margin: 0;
`

const Text = styled.div``

const Action = styled.div`
  margin-top: 20px;
`

const Button = styled.div`
  text-decoration: none;
  position: relative;
  padding-left: 18px;
  display: inline-block;
  padding-right: 6px;
`

const ButtonText = styled(motion.span)`
  position: relative;
  z-index: 2;
  display: block;

  @media ${media.maxTablet} {
    opacity: 1 !important;
  }
`

const ButtonDot = styled(motion.span)`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  /* background: var(--color-secondary); */
  position: absolute;
  top: -0.62em;
  left: -18px;
  display: block;
`

const dotVariants = {
  base: {
    scale: 0.25,
    background: '#48A851',
  },
  hover: {
    scale: 1,
    background: '#e7ebee',
    // background: '#48A851',
    transition: {
      // type: `spring`,
      // stiffness: 100,
      duration: 0.35,
    },
  },
}

const textVariants = {
  base: {
    x: 0,
  },
  hover: {
    x: -7,
    transition: {
      duration: 0.35,
    },
  },
}

export function Box({ href = '/', title, text }: any) {
  const intl = useIntl()

  return (
    <StyledLink href={href}>
      <Canvas initial="base" whileHover="hover">
        <Main>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Main>

        <Action>
          <Button>
            <ButtonDot variants={dotVariants} />
            <ButtonText variants={textVariants}>{intl.formatMessage({ id: 'more' })}</ButtonText>
          </Button>
        </Action>
      </Canvas>
    </StyledLink>
  )
}
