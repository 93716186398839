import { useEffect, useState } from 'react'
import { nanoid } from 'nanoid'

export function useId() {
  const [id, setId] = useState<string>()

  useEffect(() => {
    setId(nanoid())
  }, [])

  return id
}
