import styled, { css } from 'styled-components'

type Props = {
  active: boolean
  onToggle: () => void
}

const Button = styled.button<any>`
  width: 48px;
  height: 48px;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  color: var(--color-primary);

  span {
    display: block;
    height: 3px;
    background: currentColor;
    transition: all 0.35s ease;
    position: relative;
  }

  span:nth-child(1) {
    top: -8px;
  }

  span:nth-child(2) {
  }

  span:nth-child(3) {
    bottom: -8px;
  }

  &:hover {
    span:nth-child(2) {
      transform: scaleX(0.8);
      transform-origin: right;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      span:nth-child(1) {
        top: 3px;
        transform: rotate(-45deg);
      }

      span:nth-child(2) {
        opacity: 0;
      }

      span:nth-child(3) {
        bottom: 3px;
        transform: rotate(-140deg);
      }
    `}
`

export function MenuButton({ active, onToggle }: Props) {
  return (
    <Button onClick={onToggle} $active={active} aria-label="Menü öffnen/schliessen">
      <span></span>
      <span></span>
      <span></span>
    </Button>
  )
}
