import styled, { css } from 'styled-components'

import { renderReactText, StoryblokBlock, getTitle } from '../services'
import { container, flex, flexItem, Margin, margin, media } from '../base'
import { CTALink, FadeIn, Link } from '../components'
import { resizeImage } from '../services/storyblok'

type FeaturedBlock = StoryblokBlock & {
  text: any // TODO
  image: any // TODO
  cta_link?: any // TODO
  cta_label?: any // TODO
  visual_margin?: Margin
}

type Props = {
  block: FeaturedBlock
  withLayout?: boolean
}

const Wrapper = styled.div<any>`
  ${(props) => props.$margin && margin(props.$margin)}
  background: #e9ecf0;
  padding-top: 50px;
  padding-bottom: 50px;

  @media ${media.minTablet} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`

const Container = styled.div<any>`
  ${container()}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Media = styled.div`
  margin-bottom: 30px;

  @media ${media.minTablet} {
    ${flexItem(3, 0)}
    margin-bottom: 0;
    padding-right: 30px;
  }

  @media ${media.minDesktop} {
    ${flexItem(3, 2)}
    padding-right: 60px;
  }
`

const Photo = styled.img`
  display: block;
  height: auto;
  width: 100%;
  max-width: 380px;
`

const Main = styled.div`
  @media ${media.minTablet} {
    ${flexItem(9, 0)}
  }

  @media ${media.minDesktop} {
    ${flexItem(5, 0)}
  }
`

const Text = styled.div<any>`
  h1,
  h2 {
    margin: 0;

    &:not(:first-of-type) {
      margin-top: 30px;
    }
  }

  h1 {
    margin: 0 0 1.5rem 0;
  }

  @media ${media.minTablet} {
    ${(props) =>
      props.$large &&
      css`
        font-size: 24px; // TODO: Use from font('body-large')
      `}
  }
`

const Action = styled.div`
  margin-top: 30px;
`

export function FeaturedBlock({ block }: Props) {
  const hasLink =
    (block.cta_link.linktype === 'story' && block.cta_link.id) ||
    (block.cta_link.linktype === 'url' && block.cta_link.cached_url) ||
    (block.cta_link.linktype === 'asset' && block.cta_link.url)

  let linkTitle = ''
  if (block.cta_link.linktype === 'story' && block.cta_link.id) {
    linkTitle = getTitle(block.cta_link.story)
  }

  const resizedPhoto = resizeImage(block.image, 640, 0)

  return (
    <Wrapper $margin={block.visual_margin}>
      <Container>
        <Content>
          <Media>
            {hasLink ? (
              <Link href={block.cta_link.cached_url}>
                <Photo src={resizedPhoto.url} alt="" />
              </Link>
            ) : (
              <Photo src={resizedPhoto.url} alt={resizedPhoto.alt} />
            )}
          </Media>
          <Main>
            <FadeIn>
              <Text>{renderReactText(block.text)}</Text>
            </FadeIn>
            {hasLink && (
              <Action>
                <CTALink
                  href={block.cta_link.cached_url}
                  target={block.cta_link.linktype === 'asset' ? '_blank' : null}
                >
                  {block.cta_label || linkTitle || 'Download'}
                </CTALink>
              </Action>
            )}
          </Main>
        </Content>
      </Container>
    </Wrapper>
  )
}
