import { css } from 'styled-components'

import { font } from '../../base'

export const button = css`
  ${font('body-bold')}
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  padding: 8px 24px;
  border-bottom-right-radius: 20px;
  transition: all 0.25s ease;
  cursor: pointer;
  display: inline-block;

  &:disabled {
    background: gray;
    cursor: progress;
  }

  &:hover:not(:disabled) {
    filter: brightness(1.1);
  }
`
