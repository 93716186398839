import { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useIntl } from 'react-intl'

import { container, flex, flexItem, font, media } from '../../base'
import { mainMenuLinks } from '../../config'
import { Link, Dot } from '../../components'

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media ${media.minTablet} {
    font-size: 20px;
  }
`

const Container = styled.div`
  ${container()}
  height: 100%;

  @media ${media.maxTablet} {
    padding-top: 120px;
  }
`

const Content = styled.div`
  align-items: center;
  height: 100%;

  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div`
  margin-bottom: 40px;

  @media ${media.minTablet} {
    ${flexItem(5, 2)}
    margin-bottom: 0;
  }
`

const Meta = styled.div`
  padding-bottom: 30px;

  @media ${media.minTablet} {
    ${flexItem(3, 2)}
  }

  a {
    text-decoration: none;
  }
`

const Label = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const Items = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li``

const StyledInternalLink = styled(Link)<any>`
  ${font('heading-1')}
  display: block;
  padding: 6px 0;
  border-bottom: 1px solid #fff;
  text-decoration: none;

  @media ${media.minTablet} {
    padding: 20px 0;
  }

  /*
  &:hover,
  &.is-active {
    color: var(--color-background-light);
  }
  */
`

const BubbleWrapper = styled(motion.span)`
  position: relative;
`

const Bubble = styled(motion.span)`
  width: 80px;
  height: 80px;
  background: rgba(54, 86, 117, 0.2);
  border-radius: 80px;
  display: block;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
`

const bubbleWrapperVariants = {
  initial: {
    opacity: 1,
  },
  active: {
    opacity: 1,
    transition: { duration: 0.35 },
  },
  inactive: {
    opacity: 0.3,
    transition: { duration: 0.35 },
  },
}

const bubbleVariants = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  active: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.35 },
  },
}

export function MenuOverlay({ isActive }: any) {
  const intl = useIntl()
  const [activeIndex, setActiveIndex] = useState<any>(null)

  if (!process.browser) {
    return null
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isActive && (
        <Wrapper
          initial={{ y: '-100%' }}
          animate={{ y: '0' }}
          exit={{ y: '-100%' }}
          // transition={{ ease: 'easeInOut', duration: 0.35 }}
          transition={{ type: 'spring', stiffness: 400, damping: 40 }}
        >
          <Container>
            <Content>
              <Main>
                <Items onMouseLeave={() => setActiveIndex(null)}>
                  {mainMenuLinks.map((item: any, index: number) => {
                    let status = 'initial' // initial, inactive, active
                    if (activeIndex === index) {
                      status = 'active'
                    } else {
                      if (activeIndex !== null) {
                        status = 'inactive'
                      }
                    }

                    const title = item.title[intl.locale]

                    return (
                      <Item key={index}>
                        <StyledInternalLink
                          href={item.path}
                          onMouseEnter={() => setActiveIndex(index)}
                          onMouseLeave={() => setActiveIndex(index)}
                        >
                          <BubbleWrapper initial="initial" animate={status} variants={bubbleWrapperVariants}>
                            {title}
                            <Bubble
                              initial="initial"
                              animate={activeIndex === index ? 'active' : 'initial'}
                              variants={bubbleVariants}
                            />
                            <Dot />
                          </BubbleWrapper>
                        </StyledInternalLink>
                      </Item>
                    )
                  })}
                </Items>
              </Main>
              <Meta>
                <Label>{intl.formatMessage({ id: 'menu.address' })}</Label>
                WeTrust Group
                <br />
                Poststrasse 2 <br />
                9494 Schaan <br />
                Liechtenstein
                <br />
                <br />
                <Label>{intl.formatMessage({ id: 'directions.title' })}</Label>
                <a
                  href={intl.formatMessage({ id: 'directions.url' })}
                  target="_blank"
                  rel="noopener"
                  style={{ display: 'block' }}
                >
                  <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'directions.text' }) }} />
                </a>
                <br />
                <Label>{intl.formatMessage({ id: 'menu.phone' })}</Label>
                <a href="tel:+4232333308">+423 233 33 08</a>
                <br />
                <br />
                <Label>{intl.formatMessage({ id: 'menu.mail' })}</Label>
                <a href="mailto:office@wetrust.li">office@wetrust.li</a>
                <br />
                <br />
                <Label style={{ marginBottom: '4px' }}>{intl.formatMessage({ id: 'menu.social' })}</Label>
                <a href="https://www.linkedin.com/company/wetrustgroup/" target="_blank" rel="noopener">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin"
                    className="svg-inline--fa fa-linkedin fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    height="28"
                  >
                    <path
                      fill="currentColor"
                      d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                    />
                  </svg>
                </a>
              </Meta>
            </Content>
          </Container>
        </Wrapper>
      )}
    </AnimatePresence>,
    document.body
  )
}
