import { render, MARK_CODE } from 'storyblok-rich-text-react-renderer'

import { storyblokClient } from './storyblokClient'
import { Dot } from '../../components'

export function renderRichtext(data: any) {
  return storyblokClient.richTextResolver.render(data)
}

export function renderReactText(data: any, options: any = undefined) {
  return render(data, {
    ...options,
    markResolvers: {
      [MARK_CODE]: () => <Dot />,
    },
  })
}
