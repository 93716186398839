import styled from 'styled-components'

import format from 'date-fns/format'
import de from 'date-fns/locale/de'

type Props = {
  date: Date
}

const Time = styled.time<any>`
  display: inline-block;
`

export function Datetime({ date, ...props }: Props) {
  return (
    <Time dateTime={date.toISOString()} {...props}>
      {format(date, 'dd. MMMM yyyy', { locale: de })}
    </Time>
  )
}
