import type { Publication } from './types'

export function translatePublication(publication: Publication, locale: string): Publication {
  if (locale === 'en') {
    const translatedLanguage = publication.translated_slugs.find((language: any) => language.lang === 'en')
    if (translatedLanguage) {
      return {
        ...publication,
        name: translatedLanguage.name || publication.name, // name could be null (not yet translated)
        slug: translatedLanguage.path.replace('publikationen/', ''),
        // full_slug is already translated by Storyblok, eg. 'en/publikationen/Swiss-Withholding-Tax-Extension'
      }
    }
  }

  return publication
}
