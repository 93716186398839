import styled from 'styled-components'

import { container, font, margin, media } from '../../base'
import { Dot } from '../../components'

const Wrapper = styled.div<any>`
  background: var(--color-background-light);
  ${(props) => margin(props.$margin)}
`

const Container = styled.div`
  ${container()}
  min-height: 45vh;
  display: flex;
  align-items: flex-end;
  padding-top: 200px;
  padding-bottom: 48px;

  @media ${media.minTablet} {
    padding-left: 540px;
    padding-left: calc(((100% / 12) * 4) + var(--gap));
  }

  @media (min-width: 1600px) {
    padding-left: 544px;
  }
`

const Content = styled.div``

const Main = styled.div``

const Title = styled.h1`
  ${font('visual')}
  margin: 0 0 20px 0;
`

const Text = styled.div`
  font-size: 20px;

  @media ${media.minTablet} {
    font-size: 24px;
  }
`

export function Intro({ name, description }: any) {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Main>
            <Title>
              {name}
              <Dot />
            </Title>
            <Text>{description}</Text>
          </Main>
        </Content>
      </Container>
    </Wrapper>
  )
}
