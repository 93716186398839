import styled from 'styled-components'

const Wrapper = styled.span`
  font-size: 1.675em;
  line-height: 5px;
  color: var(--color-secondary);
`

export function Dot() {
  return <Wrapper>.</Wrapper>
}
