import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { background, font, media } from '../../base'
import { CTALink } from '../../components'

const Wrapper = styled.div`
  ${background('light')}
  overflow: hidden;
  height: 0;
  padding-top: calc(417 / 304 * 100%);
  position: relative;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Main = styled.div``

const Title = styled.h1`
  margin: 0 0 20px 0;
  padding: 0;
  ${font('heading-2')}

  @media ${media.minTablet} {
    ${font('heading-3')}
  }
`

export function Career() {
  const intl = useIntl()

  return (
    <Wrapper>
      <Content>
        <Main>
          <Title dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'career.title' }) }} />
          <CTALink href="/offene-stellen">{intl.formatMessage({ id: 'career.cta.label' })}</CTALink>
        </Main>
      </Content>
    </Wrapper>
  )
}
