import { useState } from 'react'
import styled from 'styled-components'
import orderBy from 'lodash/orderBy'
import { useIntl } from 'react-intl'

import { container, flex, flexItem, Margin, margin, media } from '../base'
import { StoryblokBlock } from '../services'
import { parseDate } from '../utils'
import { Publication, FadeIn } from '../components'
import { Filter, filterPublications, translatePublication } from '../features/publications'

type PublicationsBlock = StoryblokBlock & {
  publications: any // TODO
  visual_margin?: Margin
}

type Props = {
  block: PublicationsBlock
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
  border-bottom: 1px solid #fff; // collapsing margins
`

const Content = styled.div`
  @media ${media.minDesktop} {
    ${flex()}
  }
`

const Main = styled.div`
  @media ${media.minDesktop} {
    ${flexItem(10, 1)}
  }
`

const Items = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 30px;

    @media ${media.minTablet} {
      margin-bottom: 110px;
    }
  }
`

export function PublicationsBlock({ block }: Props) {
  const intl = useIntl()
  const [currentFilter, setCurrentFilter] = useState<string | undefined>()

  const sortedPublications = orderBy(block.publications, ['content.datetime', 'name'], ['desc', 'asc'])
  const sortedFilteredPublications = filterPublications(currentFilter, sortedPublications)

  function onFilterChange(filter?: string) {
    filter ? setCurrentFilter(filter) : setCurrentFilter(undefined)
  }

  const publications = sortedFilteredPublications.map((publication) => translatePublication(publication, intl.locale))

  return (
    <Container $margin={block.visual_margin}>
      <Content>
        <Main>
          <Filter
            currentFilter={currentFilter}
            onChange={onFilterChange}
            filters={[
              { id: 'publication', title: String(intl.messages[`publication.filter.publication`]) },
              { id: 'article', title: String(intl.messages[`publication.filter.article`]) },
              { id: 'event', title: String(intl.messages[`publication.filter.event`]) },
            ]}
          />
          <Items>
            {publications.map((publication: any) => {
              // TODO: Extract into helper function.
              const href = publication.content.link?.url
                ? `${intl.locale}/${publication.content.link.url}`
                : publication.content.file.filename

              const isLink = publication.content.link?.linktype === 'url'

              return (
                <Item key={publication.id}>
                  <FadeIn>
                    <Publication
                      publication={{
                        name: publication.name,
                        category: publication.content.category,
                        meta: publication.content.meta,
                        date: parseDate(publication.content.datetime),
                        year: publication.content.year,
                        file: publication.content.file,
                        image: publication.content.preview,
                        href,
                        isLink,
                        page: publication.content.page === 'true', // this is intentional
                        blocks: publication.content.blocks,
                        slug: publication.slug,
                        order:
                          publication.content.order === 'true'
                            ? {
                                price: Number(publication.content.order_price),
                                recipients: publication.content.order_recipients,
                              }
                            : undefined,
                      }}
                    />
                  </FadeIn>
                </Item>
              )
            })}
          </Items>
        </Main>
      </Content>
    </Container>
  )
}
