export function Placeholder({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 700" {...props}>
      <defs>
        <clipPath id="a">
          <path d="M0 0h492v700H0z" />
        </clipPath>
      </defs>
      <g data-name="Zeichenfläche – 1" clipPath="url(#a)">
        <path fill="#365575" d="M0 0h492v700H0z" />
        <g transform="translate(105 274)">
          <path
            data-name="Pfad 48"
            d="M56.951 152.387L0 0h31.718a10.715 10.715 0 0110.009 6.907l32.7 86.7-17.48 58.784zm70.2 0L70.2 0h31.577a10.715 10.715 0 0110.009 6.907l32.564 86.836z"
            fill="#fff"
          />
          <circle
            data-name="Ellipse 56"
            cx="23.542"
            cy="23.542"
            r="23.542"
            transform="translate(234.853 .141)"
            fill="#48a851"
          />
          <path
            data-name="Pfad 49"
            d="M211.593 0h-56.81v47.083l31.859-31.859v135.189h35.665V10.714A10.709 10.709 0 00211.593 0z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}
