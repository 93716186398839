import NextLink from 'next/link'
import { useRouter } from 'next/router'

type Props = {
  children?: React.ReactNode
  path?: string
  href: string | any
  locale?: string | false
  onClick?: any
}

export function Link({ href, children, locale, ...props }: Props) {
  const router = useRouter()
  const targetLocale = locale !== undefined ? locale : router.locale

  return (
    <NextLink href={href} locale={targetLocale}>
      <a {...props}>{children}</a>
    </NextLink>
  )
}
