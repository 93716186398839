export function isRichText(document: any) {
  if (!document || !document.content) return false

  if (!Array.isArray(document.content)) {
    return false
  }

  if (document.content.length < 1) {
    return false
  }

  if (document.content.length === 1 && document.content[0].type === 'paragraph' && !document.content[0].content) {
    return false
  }

  return true
}
