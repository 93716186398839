export function Logomark({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201 109" {...props}>
      <g transform="translate(.2 .4)" fillRule="nonzero" fill="none">
        <path
          d="M40.4 108.1L0 0h22.5c3.2 0 6 2 7.1 4.9l23.2 61.5-12.4 41.7zm49.8 0L49.8 0h22.4c3.2 0 6 2 7.1 4.9l23.1 61.6-12.2 41.6z"
          fill="currentColor"
        />
        <circle fill="currentColor" cx="183.3" cy="16.8" r="16.7" />
        <path d="M150.1 0h-40.3v33.4l22.6-22.6v95.9h25.3V7.6c0-4.2-3.4-7.6-7.6-7.6z" fill="currentColor" />
      </g>
    </svg>
  )
}
