import { StoryblokStory, StoryblokBlock } from '../services'
import { Blocks } from './Blocks'

type ReferenceBlock = StoryblokBlock & {
  block: StoryblokStory
}

type Props = {
  block: ReferenceBlock
}

export function ReferenceBlock({ block }: Props) {
  return <Blocks content={block.block.content} />
}
