import { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import { font, media } from '../../base'

const Wrapper = styled.div`
  padding-left: 30px;

  @media ${media.minTablet} {
    padding-left: 0;
  }

  ul {
    list-style: disc;
  }
`

const Title = styled.h2`
  ${font('heading-3')}
  margin: 0;
`

const Head = styled.div`
  position: relative;
  cursor: pointer;

  @media ${media.minTablet} {
    margin-left: -60px;
    padding-left: 60px;
  }
`

const Content = styled(motion.div)`
  overflow: hidden;
`

const Body = styled.div`
  padding-top: 10px;
`

const Icon = styled.div`
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
  left: -46px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-secondary);

  @media ${media.minTablet} {
    left: 0;
  }

  svg {
    display: block;
    width: 20px;
    height: auto;

    @media ${media.minTablet} {
      width: 24px;
    }
  }
`

export function Accordion({ title, children }: any) {
  const [isActive, setIsActive] = useState(false)

  function toggleActive() {
    setIsActive((state) => !state)
  }

  return (
    <Wrapper>
      <Head onClick={toggleActive}>
        <Icon>
          {isActive ? (
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="minus"
              className="svg-inline--fa fa-minus fa-w-12"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
              />
            </svg>
          ) : (
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="plus"
              className="svg-inline--fa fa-plus fa-w-12"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
              />
            </svg>
          )}
        </Icon>
        <Title>{title}</Title>
      </Head>
      <AnimatePresence>
        {isActive && (
          <Content
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <Body>{children}</Body>
          </Content>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}
