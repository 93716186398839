import styled from 'styled-components'

import { container, flex, flexItem, Margin, margin, media } from '../base'
import { renderRichtext, StoryblokBlock } from '../services'
import { Accordion, FadeIn } from '../components'

type AccordionBlock = StoryblokBlock & {
  items: any
  visual_margin?: Margin
}

type Props = {
  block: AccordionBlock
  withLayout?: boolean
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div`
  @media ${media.minTablet} {
    ${flexItem(6, 3)}
  }
`

const Items = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`

function AccordionBlockLayout({ children, withLayout }: any) {
  if (withLayout) {
    return (
      <Content>
        <Main>{children}</Main>
      </Content>
    )
  }

  return <>{children}</>
}

export function AccordionBlock({ block, withLayout }: Props) {
  return (
    <Container $margin={block.visual_margin}>
      <AccordionBlockLayout withLayout={withLayout}>
        <Items>
          {block.items.map((item: any) => (
            <Item key={item._uid}>
              <FadeIn>
                <Accordion title={item.title}>
                  <div dangerouslySetInnerHTML={{ __html: renderRichtext(item.text) }} />
                </Accordion>
              </FadeIn>
            </Item>
          ))}
        </Items>
      </AccordionBlockLayout>
    </Container>
  )
}
