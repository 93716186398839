import styled, { css } from 'styled-components'
import { StoryblokBlock, resizeImage } from '../services'
import { container, flex, flexItem, Margin, margin, media } from '../base'
import Image from 'next/image'
import { FadeIn } from '../components'

type Props = {
  block: GalleryBlock
}

type GalleryBlock = StoryblokBlock & {
  images: any[]
  visual_margin?: Margin
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div<any>`
  @media ${media.minTablet} {
    ${flexItem(8, 2)}

    ${(props) =>
      props.$smallWidth &&
      css`
        ${flexItem(6, 3)}
      `}
  }
`

const list = css`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: flex-end;

  @media (min-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const listItem = css`
  img {
    display: block;
  }
`

export function GalleryBlock({ block }: Props) {
  return (
    <Container $margin={block.visual_margin}>
      <Content>
        <Main>
          <FadeIn>
            <ul css={list}>
              {block.images.map((image) => {
                const resizedImage = resizeImage(image, 640, 0)
                return (
                  <li key={image.filename} css={listItem}>
                    <Image src={resizedImage.url} width={640} height={resizedImage.height} alt={image.alt} />
                  </li>
                )
              })}
            </ul>
          </FadeIn>
        </Main>
      </Content>
    </Container>
  )
}
