import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { container, flex, flexItem, Margin, margin, font, media } from '../base'
import { renderRichtext, StoryblokBlock } from '../services'
import { Map } from '../components'
import { isRichText } from '~/services/storyblok/isRichText'

type ContactBlock = StoryblokBlock & {
  address: string
  phone: string
  email: string
  lat: string
  lng: string
  directions?: string
  visual_margin?: Margin
}

type Props = {
  block: ContactBlock
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media ${media.minTablet} {
    ${flex()}
    flex-direction: row;
  }
`

const Main = styled.div`
  order: 2;

  @media ${media.minTablet} {
    ${flexItem(8, 0)}
    order: 1;
  }
`

const Meta = styled.div`
  order: 1;
  margin-bottom: 40px;

  @media ${media.minTablet} {
    ${flexItem(3, 1)}
    order: 2;
    margin-bottom: 0;
  }
`

const Label = styled.div`
  ${font('label')}
`

const Item = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

const Text = styled.div`
  font-size: 20px;
  white-space: pre;

  @media ${media.minTablet} {
    font-size: 24px;
  }

  a {
    text-decoration: none;
  }
`

const TextSimple = styled(Text)`
  white-space: normal;

  a {
    text-decoration: underline;
  }
`

export function ContactBlock({ block }: Props) {
  const intl = useIntl()

  return (
    <Container $margin={block.visual_margin}>
      <Content>
        <Main>
          <Map lat={parseFloat(block.lat)} lng={parseFloat(block.lng)} />
        </Main>
        <Meta>
          <Item>
            <Label>{intl.formatMessage({ id: 'contact.address' })}</Label>
            <Text>{block.address}</Text>
          </Item>
          {isRichText(block.directions) && (
            <Item>
              <Label>{intl.formatMessage({ id: 'directions.title' })}</Label>
              <TextSimple>
                <div dangerouslySetInnerHTML={{ __html: renderRichtext(block.directions) }} />
              </TextSimple>
            </Item>
          )}
          <Item>
            <Label>{intl.formatMessage({ id: 'contact.phone' })}</Label>
            <Text>
              <a href={`tel:${block.phone}`}>{block.phone}</a>
            </Text>
          </Item>
          <Item>
            <Label>{intl.formatMessage({ id: 'contact.mail' })}</Label>
            <Text>
              <a href={`mailto:${block.email}`}>{block.email}</a>
            </Text>
          </Item>
          <Item>
            <Label style={{ marginBottom: '4px' }}>{intl.formatMessage({ id: 'contact.social' })}</Label>
            <a href="https://www.linkedin.com/company/wetrustgroup/" target="_blank" rel="noopener">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="linkedin"
                className="svg-inline--fa fa-linkedin fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                height="32"
              >
                <path
                  fill="currentColor"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                />
              </svg>
            </a>
          </Item>
        </Meta>
      </Content>
    </Container>
  )
}
