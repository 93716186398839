import { useEffect, useState } from 'react'
import shuffle from 'lodash/shuffle'

export function useRandomTeam(originalMembers: any) {
  const [members, setMembers] = useState(originalMembers)

  useEffect(() => {
    setMembers((members: any) => shuffle(members))
  }, [])

  return members
}
