import React from 'react'

import { useId } from './useId'

import * as styles from './Checkbox.css'

type Props = {
  error?: boolean
  children: React.ReactNode
} & any // TODO: Checkbox types

export const Checkbox = React.forwardRef<any, Props>(({ error, children, ...props }, ref) => {
  const id = useId()

  return (
    <div css={[styles.container, error && styles.containerError]}>
      <input type="checkbox" id={id} css={styles.checkbox} ref={ref} {...props} />
      <label css={styles.label} htmlFor={id}>
        {children}
      </label>
    </div>
  )
})
