import styled from 'styled-components'

import { StoryblokBlock } from '../services'
import { container, flex, flexItem, Margin, margin, media } from '../base'
import { Dot } from '../components'

type HeadingBlock = StoryblokBlock & {
  text: any
  visual_margin?: Margin
}

type Props = {
  block: HeadingBlock
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
`

const Content = styled.div`
  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div`
  @media ${media.minTablet} {
    ${flexItem(8, 2)}
  }
`

const Title = styled.h1`
  margin: 0;
`

export function HeadingBlock({ block }: Props) {
  return (
    <Container $margin={block.visual_margin}>
      <Content>
        <Main>
          <Title>
            {block.text}
            <Dot />
          </Title>
        </Main>
      </Content>
    </Container>
  )
}
