import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { useCookies } from 'react-cookie'

import { container, media } from '../../base'
import { Logo, Link } from '../../components'

const Wrapper = styled.div`
  margin-top: 30px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 98;

  @media ${media.minTablet} {
    z-index: 999;
    margin-top: 84px;
  }
`

const Container = styled.div`
  ${container()}
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Main = styled.div``

const Meta = styled.div`
  display: flex;
  align-items: center;
  padding-right: 26px;

  @media ${media.minTablet} {
    padding-right: 54px;
  }
`

const StyledLogo = styled(Logo)`
  display: block;
  height: 28px;

  @media ${media.minTablet} {
    height: 31px;
  }
`

const Languages = styled.ul`
  margin: -8px;
  padding: 0;
  list-style: none;
  margin-right: 36px;
  display: flex;
  font-size: 16px;

  @media ${media.maxTablet} {
    font-size: 15px;
  }
`

type Language = {
  isActive?: boolean
}

const Language = styled.li<Language>`
  a {
    text-decoration: none;
    display: block;
    padding: 8px;

    &:hover {
      font-weight: bold;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: bold;
    `}
`

export function Header() {
  const [cookie, setCookie] = useCookies(['NEXT_LOCALE'])
  const router = useRouter()
  const intl = useIntl()

  function switchLanguage(locale: string) {
    if (cookie.NEXT_LOCALE !== locale) {
      setCookie('NEXT_LOCALE', locale, { path: '/' })
    }

    // router.push('/', '/', { locale })
    setTimeout(() => {
      router.reload()
    }, 200)
  }

  return (
    <Wrapper>
      <Container>
        <Main>
          <Link href="/" aria-label={intl.formatMessage({ id: 'home' })}>
            <StyledLogo />
          </Link>
        </Main>
        <Meta>
          <Languages>
            <Language isActive={router.locale === 'de'}>
              <Link href="/" locale="de" onClick={() => switchLanguage('de')}>
                DE
              </Link>
            </Language>
            <Language isActive={router.locale === 'en'}>
              <Link href="/" locale="en" onClick={() => switchLanguage('en')}>
                EN
              </Link>
            </Language>
          </Languages>
          {/* <MenuButton active={isMenuOverlayOpen} onToggle={toggleMenuOverlay} /> */}
        </Meta>
      </Container>
    </Wrapper>
  )
}
