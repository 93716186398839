import * as styles from './Button.css'

export type ButtonProps = {
  children: React.ReactNode
  type?: 'button' | 'submit' // TODO: Inherit from HTML button
  onClick?: any // TODO: Inherit from HTML button
  disabled?: any
}

export function Button({ children, type, onClick, disabled, ...props }: ButtonProps) {
  return (
    <button css={styles.button} onClick={onClick} disabled={disabled} type={type} {...props}>
      {children}
    </button>
  )
}
