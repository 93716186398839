import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'

import { margin } from '../../base'
import { Filters } from './types'

type Props = {
  currentFilter?: string
  onChange: (filter?: string) => void
  filters: Filters
}

const Wrapper = styled.div`
  ${margin('small')}
`

const Items = styled.ul`
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 24px;
  }
`

const Button = styled.button`
  background: none;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

const Indicator = styled.span<any>`
  display: inline-block;
  width: 12px;
  height: 12px;
  background: var(--color-background-light);
  border-radius: 12px;
  margin-right: 2px;

  ${(props) =>
    props.isActive &&
    css`
      background: var(--color-secondary);
    `}
`

export function Filter({ currentFilter, onChange, filters }: Props) {
  const intl = useIntl()

  return (
    <Wrapper>
      <Items>
        <Item>
          <Button onClick={() => onChange()}>
            <Indicator isActive={!currentFilter} /> {intl.formatMessage({ id: 'publications.filter.all' })}
          </Button>
        </Item>
        {filters.map((filter) => (
          <Item key={filter.id}>
            <Button onClick={() => onChange(filter.id)}>
              <Indicator isActive={currentFilter === filter.id} /> {filter.title}
            </Button>
          </Item>
        ))}
      </Items>
    </Wrapper>
  )
}
