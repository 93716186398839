import { css } from 'styled-components'

type Options = {
  size: 'medium' | 'large'
  error?: boolean
}

export function dropdown({ size, error }: Options) {
  return css`
    position: relative;

    select {
      appearance: none;
      background: var(--color-background-light);
      display: block;
      width: 100%;
      padding: 8px 16px;
      cursor: pointer;
      font-size: inherit;
      font-family: inherit;
      border: none;
      border-radius: none;
      color: inherit;
      outline-color: var(--color-primary);

      &:required:invalid {
        color: #8194a8;
      }
    }

    svg {
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      pointer-events: none;
    }

    ${size === 'large' &&
    css`
      select {
        padding: 16px 22px;
      }
    `}

    ${error &&
    css`
      select {
        background: #ec121229;
      }
    `}
  `
}
