import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { font, media } from '../../base'
import { CTALink } from '../CTALink'

const Link = styled.a`
  display: block;
  text-decoration: none;
  height: 100%;
  width: 100%;
`

const Meta = styled.div``

const Category = styled.span``

const Title = styled.h2`
  margin: 0;
  ${font('body-bold')}
  font-size: 20px;
  max-width: 860px;

  @media ${media.minTablet} {
    font-size: 24px;
  }
`

const Actions = styled.div`
  margin-top: 30px;

  > a:not(:last-child) {
    margin-right: 30px;
  }

  @media ${media.minTablet} {
    margin-top: 30px;
  }
`

const Description = styled.div``

export function PublicationPreview({ publication }: any) {
  const intl = useIntl()

  const category = intl.messages[`publication.category.${publication.category}`]

  const primaryActionHref = publication.order
    ? `${intl.locale === 'en' ? 'en/' : ''}publikationen/${publication.slug}?order=1`
    : publication.page
    ? `${intl.locale === 'en' ? 'en/' : ''}publikationen/${publication.slug}`
    : publication.href

  const openBlank = primaryActionHref.startsWith('http') ? true : false

  return (
    <Link href={primaryActionHref} target={openBlank ? '_blank' : '_self'} aria-label="Publikation öffnen">
      <Meta>
        {publication.year}
        &nbsp;| <Category>{category}</Category>
      </Meta>
      <Title>{publication.name}</Title>
      <Description>{publication.meta}</Description>
      <Actions>
        {publication.order ? (
          <>
            {/* Order publication */}
            <CTALink href={`/publikationen/${publication.slug}?order=1`}>
              {intl.formatMessage({ id: 'publication.order' })}
            </CTALink>
            {publication.file.filename && (
              <CTALink href={publication.file.filename} target="_blank">
                {intl.formatMessage({ id: 'publication.preview' })}
              </CTALink>
            )}
          </>
        ) : (
          <>
            {/* Download or link */}
            {publication.page ? (
              <CTALink href={`/publikationen/${publication.slug}`}>{intl.formatMessage({ id: 'more' })}</CTALink>
            ) : (
              <CTALink href={publication.href} target="_blank" rel="noopener">
                {publication.isLink
                  ? intl.formatMessage({ id: 'publication.open' })
                  : intl.formatMessage({ id: 'publication.download' })}
              </CTALink>
            )}
          </>
        )}
      </Actions>
    </Link>
  )
}
