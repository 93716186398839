import styled from 'styled-components'

import { StoryblokBlock } from '../services/storyblok'
import { container, font, Margin, margin, media } from '../base'
import { Dot } from '../components'

type IntroBlock = StoryblokBlock & {
  title: string
  text: string
  visual_margin?: Margin
}

type Props = {
  block: IntroBlock
}

const Wrapper = styled.div<any>`
  background: var(--color-background-light);
  ${(props) => margin(props.$margin)}
`

const Container = styled.div`
  ${container()}
  min-height: 45vh;
  display: flex;
  align-items: flex-end;
  padding-top: 200px;
  padding-bottom: 48px;
`

const Content = styled.div``

const Main = styled.div``

const Title = styled.h1`
  ${font('visual')}
  margin: 0 0 20px 0;
`

const Text = styled.div`
  font-size: 20px;

  @media ${media.minTablet} {
    font-size: 24px;
  }
`

export function IntroBlock({ block }: Props) {
  return (
    <Wrapper $margin={block.visual_margin}>
      <Container>
        <Content>
          <Main>
            <Title>
              {block.title}
              <Dot />
            </Title>
            <Text>{block.text}</Text>
          </Main>
        </Content>
      </Container>
    </Wrapper>
  )
}
