export const mainMenuLinks = [
  {
    title: { de: 'Expertise', en: 'Expertise' },
    path: '/expertise',
  },
  {
    title: { de: 'Publikationen', en: 'Publications' },
    path: '/publikationen',
  },
  {
    title: { de: 'Team', en: 'Team' },
    path: '/team',
  },
  {
    title: { de: 'Über uns', en: 'About us' },
    path: '/ueber-uns',
  },
  {
    title: { de: 'Karriere', en: 'Career' },
    path: '/offene-stellen',
  },
  {
    title: { de: 'Kontakt', en: 'Contact' },
    path: '/kontakt',
  },
]
